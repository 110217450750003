<template>
  <base-modal :title="$t('addmember')" @close="() => this.$ionic.modalController.dismiss()">
    <ion-content>
      <ion-list>
        <ion-item button @click="openSearchMemberModal" color="dark">
          <ion-label v-if="!member" >
            {{ $t('clicktoselectmember') }}
          </ion-label>
          <ion-label v-else>
            {{ $t('changemember') }}
          </ion-label>
        </ion-item>

        <ion-item v-if="member">
          <ion-label color="white">
            <ion-text color="moto">{{ $t('selectedmember') }}</ion-text>
            <p>{{ member.first_name }} {{ member.last_name }}</p>
            <p>{{ member.email }}</p>
          </ion-label>
        </ion-item>

        <ion-item>
          <ion-label position="stacked">{{ $t('subscription') }}</ion-label>
          <ion-select @ionChange="subscription_id = $event.target.value">
            <ion-select-option v-for="subscription in getEnabledFacilitySubscriptions" :key="subscription.id" :value="subscription.id">
              {{ subscription.name }}
            </ion-select-option>
          </ion-select>
        </ion-item>

        <ion-item>
          <ion-label position="stacked">{{ $t('expiresat') }}</ion-label>
          <ion-datetime
            required
            display-format="DD-MM-YYYY"
            :value="date"
            :min="$moment().format('YYYY-MM-DD')"
            :max="$moment().add('5', 'years').format('YYYY-MM-DD')"
            @ionChange="date = $event.target.value"
          />
        </ion-item>
      </ion-list>

      <Error :error="error" />
    </ion-content>

    <ion-footer>
      <loading-button color="moto" :loading="loading" :disabled="buttonDisabled" @click="addMember">
        <ion-label>{{ $t('addmember') }}</ion-label>
      </loading-button>
    </ion-footer>
  </base-modal>
</template>

<script>
import { GET_ENABLED_FACILITY_SUBSCRIPTIONS, GET_USER } from '../../graphql/queries'
import { ADD_MEMBER } from '../../graphql/mutations';
import LoadingButton from '../LoadingButton.vue'
import BaseModal from './BaseModal.vue'
import SearchMemberModal from './SearchMemberModal.vue'
import * as moment from 'moment';
import { SET_CURRENT_USER_MUTATION } from '@/store/store-mutations';

export default {
  components: { BaseModal, LoadingButton },
  props: {
    facility_id: {}
  },
  data() {
    return {
      error: null,
      member: null,
      loading: false,
      date: (moment().isAfter(moment().set({ 'month': 10, 'date': 1 }))) ? moment().set({ 'month': 11, 'date': 31 }).add('year', 1) : moment().set({ 'month': 11, 'date': 31 }),
      subscription_id: null,
    }
  },
  computed: {
    buttonDisabled() {
      return !this.member || this.loading || !this.date || !this.subscription_id;
    }
  },
  apollo: {
    getEnabledFacilitySubscriptions: {
      query: GET_ENABLED_FACILITY_SUBSCRIPTIONS,
      variables() {
        return {
          facility_id: this.facility_id,
        }
      },
      fetchPolicy: 'network-only',
    }
  },
  methods: {
    async addMember() {
      this.error = null;

      try {
        await this.$apollo.mutate({
          mutation: ADD_MEMBER,
          variables: {
            data: {
              user_id: this.member.id,
              subscription_id: this.subscription_id,
              expires_at: this.$moment(this.date).format('YYYY-MM-DD')
            },
            facility_id: this.facility_id,
          }
        })

        this.$parent.$apollo.queries.getFacilityMembers.refetch();

        const {data: getUserByToken} = await this.$apollo.query({
          query: GET_USER
        })

        this.$store.commit(SET_CURRENT_USER_MUTATION, getUserByToken?.getUserByToken);

        this.$ionic.modalController.dismiss();
      } catch (e) {
        this.error = e;
      }
    },
    async openSearchMemberModal() {
      const modal = await this.$ionic.modalController.create({
        component: SearchMemberModal,
        componentProps: {
          parent: this,
          propsData: {
            facility_id: this.facility_id,
          }
        },
      });

      await modal.present();
    },
  }
}
</script>
