<template>
  <div>
    <ion-header translucent>
      <ion-toolbar>
        <ion-title class="pl-0">
          {{ $t('addadmin') }}
        </ion-title>
        <ion-icon slot="end" size="large" name="close" @click="closeMe" />
      </ion-toolbar>
      <ion-toolbar>
        <ion-searchbar :value="search" :placeholder="$t('searchforadmins')" @ionInput="search = $event.target.value" />
      </ion-toolbar>
    </ion-header>
    <ion-content class="ion-padding">
      <ion-list>
        <ion-item v-for="admin in computedAdmins" :key="admin.id" @click="addAdmin(admin)">
          <ion-label color="white">
              <p>{{ admin.first_name }} {{ admin.last_name }}</p>
              <p>{{ admin.email }}</p>
          </ion-label>
        </ion-item>
      </ion-list>
    </ion-content>
  </div>
</template>

<script>
    import { SEARCH_ADMINS } from "@/graphql/queries";
    import { ADD_ADMIN } from "@/graphql/mutations";

    export default {
    name: 'Modal',
    props: {
      admins: { type: Array },
      closeMe: { type: Function, default: () => {} },
      facility: { type: Object }
    },
    data() {
      return {
        search: '',
        currentAdminIds: []
      }
    },
    computed: {
      computedAdmins() {
        if(this.searchAdmins) {
          return this.searchAdmins.filter((searchAdmin) => {
            return !this.currentAdminIds.includes(searchAdmin.id)
          })
        } else {
          return [];
        }
      }
    },
      created() {
        this.admins.forEach(admin => {
          this.currentAdminIds.push(admin.id)
        })
      },
    apollo: {
      searchAdmins: {
        query: SEARCH_ADMINS,
        debounce: 1000,
        variables() {
            return {
              facility_id: this.facility.id,
              search: this.search
            }
        }
      }
    },
    methods: {
      alreadyAdmin(admin) {
        this.admins.forEach(currentAdmin => {
          if(currentAdmin.id == admin.id) {
            return true;
          }
        })

        return false;
      },
      addAdmin(admin) {
        this.$apollo.mutate({
          mutation: ADD_ADMIN,
          variables: {
            facility: this.facility.id,
            admin: admin.id
          }
        }).then(() => {
          this.closeMe();
        })
      }
    }
  }
</script>
