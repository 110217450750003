<template>
  <ion-list-header>
    <ion-label>{{ $t('filtereventsby') }}:</ion-label>
    <ion-button @click="chooseFilter" class="left-auto">
      <ion-text>
        {{ selectedFilter.label }}
      </ion-text>
    </ion-button>
  </ion-list-header>
</template>

<script>
  import { choose } from "@/utils";

  export default {
    emits: ['change'],
    props: {
      defaultFilterBy: {
        type: String,
        default: 'PLANNED'
      }
    },
    data() {
      return {
        filterBy: this.defaultFilterBy ?? 'PLANNED',
        filterOptions: [
          {
            key: 'ALL',
            label: this.$t('alleventsfilter'),
          },
          {
            key: 'PLANNED',
            label: this.$t('plannedeventsfilter'),
          },
          {
            key: 'CANCELLED',
            label: this.$t('cancelledeventsfilter'),
          },
        ]
      }
    },
    computed: {
      selectedFilter() {
        return this.filterOptions.find((option) => option.key === this.filterBy)
      }
    },
    methods: {
      choose,
      async chooseFilter($event) {
        const filterBy = await this.choose('Filter', null, this.filterOptions, {
          selectedOption: this.selectedFilter,
          event: $event
        })

        if (!filterBy) {
          return;
        }

        this.filterBy = filterBy.key;

        this.$emit('change', filterBy);
      }
    }
  }
</script>