<template>
  <ion-list>
    <ion-list-header>
      <ion-label>{{ $t('sortby') }}:</ion-label>
    </ion-list-header>
    <ion-item v-for="item in items" :key="item.value" @click="clickItem(item)">
      {{ item.label }}
      <ion-icon v-if="checkActive(item)" slot="end" name="checkmark" />
    </ion-item>
  </ion-list>
</template>

<script>
    export default {
      props: {
        items: {},
        activeItem: {},
        dismiss: {type: Function, required: true},
        selectItem: {type: Function, required: true}
      },
      methods: {
        clickItem(item) {
          this.selectItem(item);
          this.dismiss();
        },
        checkActive(item) {
          let checkIsTrue = false;

          if(this.activeItem.key == item.key) {
            checkIsTrue = true;
          }

          return checkIsTrue;
        }
      }
    }
</script>
