<template>
  <base-modal :title="$t('tocheckout')" @close="close">
    <ion-content>
      <SelectPaymentMethod :value="payment_method" @change="updatePaymentMethod" />

      <ion-list lines="full">
        <ion-list-header>
          <ion-label>{{ $t('paymentoverview') }}</ion-label>
        </ion-list-header>

        <div>
          <ion-item v-for="item in line_items" :key="item.ticket_id">
            <ion-text class="text-sm">
              {{ item.quantity }} x {{ item.name }}
            </ion-text>
            <ion-label slot="end" class="text-right">
              {{ item.quantity * item.price | priceAmount }}
            </ion-label>
          </ion-item>
          <ion-item v-if="surCharge">
            <ion-text class="text-sm">
              {{ $t('paymentsurcharge') }}
            </ion-text>
            <ion-label slot="end" class="text-right">
              {{ surCharge | stripeAmount}}
            </ion-label>
          </ion-item>
          <ion-item>
            <ion-text class="text-sm" color="moto">
              {{ $t('total') }}
            </ion-text>
            <ion-label slot="end" class="text-right">
              {{ totalStripeAmount + surCharge | stripeAmount}}
            </ion-label>
          </ion-item>
        </div>
        
        <ion-item>
          <ion-label class="text-xs" text-wrap>
            {{ $t('servicecosts') }}
          </ion-label>
        </ion-item>
      </ion-list>
    </ion-content>

    <ion-footer>
      <ion-toolbar>
        <ion-button expand="full" color="moto" :disabled="loading || !payment_method" @click="pay">
          <ion-label v-if="payment_method">
            {{ $t('topaywithmethod', { method: $t(`paymentmethod${payment_method}`) }) }}
          </ion-label>
          <ion-label v-else>
            {{ $t('selectapaymentmethod') }}
          </ion-label>
        </ion-button>
      </ion-toolbar>
    </ion-footer>
  </base-modal>
</template>

<script>
  import BaseModal from "@/components/modals/BaseModal";
  import { stripeFee } from "@/utils";
  import SelectPaymentMethod from "@/components/stripe/SelectPaymentMethod";

  export default {
    components: {
      SelectPaymentMethod,
      BaseModal,
    },
    props: ['closeMe', 'line_items', 'confirm'],
    data() {
      return {
        payment_method: null,
        loading: false,
      }
    },
    computed: {
      totalStripeAmount() {
        return Math.ceil(this.line_items.reduce( (carry,item) => {
          return carry + item.quantity * item.price;
        }, 0) * 100);
      },
      surCharge() {
        return this.stripeFee(this.totalStripeAmount, this.payment_method);
      }
    },
    methods: {
      stripeFee,

      close() {
        this.closeMe();
      },

      pay() {
        this.confirm(this.payment_method);
      },

      updatePaymentMethod(payment_method) {
        this.payment_method = payment_method;
      }
    }
  }
</script>
