<template>
  <base-modal :title="$t('editadmin')" @close="$ionic.modalController.dismiss()">
    <ion-content>
      <ion-list>
        <ion-list-header>
          <ion-label>{{ $t('name') }}</ion-label>
        </ion-list-header>
        <ion-item>
          <ion-label>{{ admin.first_name}} {{ admin.last_name }}</ion-label>
        </ion-item>
      </ion-list>
    </ion-content>

    <ion-footer>
      <ion-button expand="full" @click="$ionic.modalController.dismiss()">
        <ion-label>{{ $t('save') }}</ion-label>
      </ion-button>
      <ion-button color="danger" expand="full" @click="removeTheAdmin">
        <ion-label>{{ $t('remove') }}</ion-label>
      </ion-button>
    </ion-footer>
  </base-modal>
</template>

<script>
import BaseModal from './BaseModal.vue';
import { REMOVE_ADMIN } from '@/graphql/mutations';

const ROLES = {
  ADMIN: {
    label: 'Admin',
  },
  MANAGER: {
    label: 'Manager'
  }
}

export default {
  props: ['admin', 'facility'],
  components: {
    BaseModal,
  },
  data() {
    return {
      ROLES
    }
  },
  methods: {
    async removeTheAdmin() {
      const removed = await this.$apollo.mutate({
        mutation: REMOVE_ADMIN,
        errorPolicy: 'all',
        variables: {
          facility_id: this.facility.id,
          admin_id: this.admin.id
        }
      });

      if(removed?.errors?.[0]) {
        const toast = await this.$ionic.toastController.create({
          color: 'danger',
          duration: 3000,
          message: removed.errors[0].message
        });
        toast.present();
        this.$ionic.modalController.dismiss();
        return;
      }

      const toast = await this.$ionic.toastController.create({
          color: 'success',
          duration: 3000,
          message: 'Admin is removed succesfully'
      });
      toast.present();
      this.$parent.$apollo.queries.facility.refetch();
      this.$ionic.modalController.dismiss();
    }
  }
}
</script>