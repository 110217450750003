<template>
  <List
    :header="$t('passedeventstitle')"
    :not-found-message="$t('nopassedeventslabel')"
  >
    <template v-slot:top>
      <PassedEventSelectionFilter @change="changeFilter" />
    </template>

    <template v-if="events && events.data && events.data.length">
      <div v-for="event in events.data" :key="event.id" @click="openSingleEvent(event.id)">
        <EventItem :event="event" button />
      </div>
      <ion-item v-if="events.paginatorInfo.hasMorePages" @click="openPastEvents" button>
        <ion-label class="text-center">
          <span class="text-sm font-bold flex items-center flex-1 justify-center">{{ $t('showall') }} <ion-icon name="arrow-forward" /></span>
        </ion-label>
      </ion-item>
    </template>
  </List>
</template>

<script>
  import { GET_PASSED_FACILITY_EVENTS } from '@/graphql/queries'
  import { defaultPaginationConfig } from '@/configs'
  import EventItem from '@/components/items/EventItem'
  import { choose } from "@/utils";
 import PassedEventSelectionFilter from '@/components/items/PassedEventSelectionFilter';

  export default {
    components: {
      EventItem,
      PassedEventSelectionFilter
    },
    data() {
      return {
        filterBy: 'HELD'
      }
    },
    props: ['id'],
    methods: {
      choose,
      openSingleEvent(id) {
        this.$router.push({name: this.$routeNames.EVENT, params: {id}})
      },
      openPastEvents() {
        this.$router.push({name: this.$routeNames.FACILITY_PASSED_EVENTS, params: {id: this.id}})
      },
      changeFilter(filterOption) {
        this.filterBy = filterOption.key
      }
    },
    apollo: {
      events: {
        query: GET_PASSED_FACILITY_EVENTS,
        fetchPolicy: 'network-only',
        variables() {
          return {
            facility_id: this.id,
            filter_by: this.filterBy,
            ...defaultPaginationConfig,
            first: 5,
          }
        },
        update: (data) => data.getPassedFacilityEvents
      }
    }
  }
</script>
