<template>
  <div>
    <ion-toolbar>
      <ion-button slot="end" class="mr-4" color="moto" size="small" @click="openTopUpModal">
        {{ $t('topup') }}
      </ion-button>
    </ion-toolbar>
    <List
      :header="$t('balance')"
    >
      <ion-item>
        <ion-label type="stacked">{{ $t('balance') }}</ion-label>
        <ion-text>
          {{ stripeAmount(stripe_balance) }}
        </ion-text>
      </ion-item>
      <ion-item v-if="pending_refunds_quantity">
        <ion-label type="stacked">{{ $t('pendingrefundsamount') }}</ion-label>
        <ion-text>
          {{ stripeAmount(-pending_refunds_amount) }}
        </ion-text>
      </ion-item>
      <ion-item v-if="pending_refunds_quantity">
        <ion-label type="stacked">{{ $t('numberofpendingrefunds') }}</ion-label>
        <ion-text>
          {{ pending_refunds_quantity }}
        </ion-text>
      </ion-item>
    </List>
    <List :header="$t('topups')">
      <template v-for="topup in top_ups">
        <ion-item :key="topup.id">
          <ion-label text-wrap slot="start">
            <h3>
              <ion-text color="moto">
                {{ topup.created_at|formatDateTime }}
              </ion-text>
            </h3>
            <ion-text>
              <h2>{{ topup.description || $t('nodescription') }}</h2>
            </ion-text>
          </ion-label>
          <ion-text color="moto" slot="end" class="text-right">
            <ion-badge>{{ $t(topup.order.stripe_status + 'status') }}</ion-badge>
            <div>
            {{ stripeAmount(topup.amount) }}
            </div>
          </ion-text>
        </ion-item>
      </template>
    </List>
  </div>
</template>

<script>
  import { stripeAmount } from "@/utils";
  import TopUpModal from "@/components/modals/TopUpModal";
  import { GET_BALANCE, GET_TOP_UPS } from "@/graphql/queries";

  export default {
    name: "TopUps",
    props: ['id', 'facility'],
    data() {
      return {
        stripeAmount,
        stripe_balance: 0,
        pending_refunds_quantity: 0,
        pending_refunds_amount: 0,
        top_ups: [],
      }
    },
    mounted() {
      this.getBalance();
      this.getTopUps();
    },
    methods: {
      async openTopUpModal() {
        const modal = await this.$ionic.modalController
          .create({
            component: TopUpModal,
            componentProps: {
              parent: this,
              propsData: {
                facility_id: this.id,
                closeMe: () => {
                  modal.dismiss()
                },
              },
            },
          });

        return modal.present();
      },
      async getBalance() {
        const data = await this.$apollo.query(
          {
            fetchPolicy: 'network-only',
            query: GET_BALANCE,
            variables: {
              facility_id: this.id,
            }
          }
        );
        this.pending_refunds_amount = data.data.getBalance.pending_refunds_amount
        this.pending_refunds_quantity = data.data.getBalance.pending_refunds_quantity
        this.stripe_balance = data.data.getBalance.stripe_balance
      },
      async getTopUps() {
        const data = await this.$apollo.query(
          {
            fetchPolicy: 'network-only',
            query: GET_TOP_UPS,
            variables: {
              facility_id: this.id,
            }
          }
        );
        this.top_ups = data.data.getTopUps
      }
    }
  }
</script>