<template>
  <base-modal :title="$t('editmembership')" @close="() => this.$ionic.modalController.dismiss()">
    <ion-content>
      <ion-list>
        <ion-item v-if="member">
          <ion-label color="white">
            <ion-text color="moto">{{ $t('selectedmember') }}</ion-text>
            <p>{{ member.first_name }} {{ member.last_name }}</p>
            <p>{{ member.email }}</p>
          </ion-label>
        </ion-item>

        <ion-item v-if="getFacilitySubscriptions">
          <ion-label position="stacked">{{ $t('subscription') }}</ion-label>
          <ion-select @ionChange="subscription_id = $event.target.value" :value="subscription_id">
            <ion-select-option
              v-for="subscription in getFacilitySubscriptions"
              :key="subscription.id"
              :value="subscription.id"
              :disabled="!subscription.is_live">
              {{ subscription.name }}
            </ion-select-option>
          </ion-select>
        </ion-item>
        <ion-item v-else>
          <ion-label color="warning">{{ $t('nosubscriptionsfound') }}</ion-label>
        </ion-item>

        <ion-item>
          <ion-label position="stacked">{{ $t('expiresat') }}</ion-label>
          <ion-datetime
            required
            display-format="DD-MM-YYYY"
            :value="date"
            :max="$moment().add('5', 'years').format('YYYY-MM-DD')"
            @ionChange="date = $event.target.value"
          />
        </ion-item>
      </ion-list>
    </ion-content>

    <ion-footer>
      <loading-button color="moto" :loading="loading" :disabled="buttonDisabled" @click="updateFacilityMember">
        <ion-label>{{ $t('editmembershipaction') }}</ion-label>
      </loading-button>
    </ion-footer>
  </base-modal>
</template>

<script>
  import { GET_FACILITY_SUBSCRIPTIONS } from '../../graphql/queries'
import { UPDATE_FACILITY_MEMBER } from '../../graphql/mutations';
import LoadingButton from '../LoadingButton.vue'
import BaseModal from './BaseModal.vue'
import * as moment from 'moment';
import { GET_USER_BY_TOKEN_ACTION } from "@/store/store-actions";

export default {
  components: { BaseModal, LoadingButton },
  props: {
    facilityMember: {
      type: Object,
      default: undefined
    },
    facility_id: {},
  },
  data() {
    return {
      member: this.facilityMember.user,
      loading: false,
      date: moment(this.facilityMember.expires_at),
      subscription_id: this.facilityMember.subscription.id,
    }
  },
  computed: {
    buttonDisabled() {
      return !this.member || this.loading || !this.date || !this.subscription_id;
    }
  },
  apollo: {
    getFacilitySubscriptions: {
      query: GET_FACILITY_SUBSCRIPTIONS,
      variables() {
        return {
          facility_id: this.facility_id,
        }
      }
    }
  },
  methods: {
    async updateFacilityMember() {
      await this.$apollo.mutate({
        mutation: UPDATE_FACILITY_MEMBER,
        variables: {
          id: this.facilityMember.id,
          data: {
            subscription_id: this.subscription_id,
            expires_at: this.$moment(this.date).format('YYYY-MM-DD')
          },
          facility_id: this.facility_id,
        }
      })

      this.$parent.$apollo.queries.getFacilityMembers.refetch();

      // Refresh the current user, ensuring to update its own subscriptions
      this.$store.dispatch(GET_USER_BY_TOKEN_ACTION, {fresh: true});

      this.$ionic.modalController.dismiss();
    },
  }
}
</script>
