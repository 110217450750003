<template>
  <base-modal :title="$t('addsubscription')" @close="() => this.$ionic.modalController.dismiss()">
    <template>
        <ion-content>
        <ion-list>
          <ion-item>
            <ion-label position="stacked">
              {{ $t('name') }}
            </ion-label>
            <ion-input type="text" :value="input.name" @ionInput="input.name = $event.target.value" />
          </ion-item>

          <ion-item>
            <ion-label position="stacked">
              {{ $t('price') }}
            </ion-label>
            <ion-input type="number" :min="0" :value="input.price" @ionInput="input.price = parseInt($event.target.value)" />
          </ion-item>

          <ion-item>
            <ion-label position="stacked">
              {{ $t('maxcapacityleaveblankformaxcapacity') }}
            </ion-label>
            <ion-input type="number" min="1" :value="input.max_capacity" @ionInput="input.max_capacity = $event.target.value ? parseInt($event.target.value) : null" />
          </ion-item>

          <!-- <ion-item>
            <ion-label position="stacked">
              Max per account
            </ion-label>

            <ion-input type="number" :min="1" :value="input.max_per_account" @ionInput="input.max_per_account = $event.target.value" />
          </ion-item>

          <ion-item>
            <ion-label position="stacked">Start selling</ion-label>
            <ion-select :value="selectedSelling" @ionChange="changeSelectedSelling">
              <ion-select-option v-for="select in Object.values(startSellingSelects)" :key="select.key" :value="select.key">{{ select.label }}</ion-select-option>
            </ion-select>
          </ion-item>

          <ion-item v-if="selectedSelling === startSellingSelects.AT_START_DATE.key">
            <ion-label position="stacked">
              Opens At
            </ion-label>
            <ion-datetime
              display-format="MMM DD, YYYY"
              :min="minOpensAt"
              :value="input.opens_at"
              picker-format="DD MM YYYY"
              @ionChange="changeOpensAt($event)"
            />
          </ion-item>

          <ion-item>
            <ion-label position="stacked">Stop selling</ion-label>
            <ion-select :value="selectedStopSelling" @ionChange="changeSelectedStopSelling">
              <ion-select-option v-for="select in Object.values(stopSellingSelects)" :key="select.key" :value="select.key">{{ select.label }}</ion-select-option>
            </ion-select>
          </ion-item>

          <ion-item v-if="selectedStopSelling !== stopSellingSelects.NEVER.key">
            <ion-label position="stacked">
              Closes At
            </ion-label>
            <ion-datetime
              display-format="MMM DD, YYYY"
              :min="minClosesAt"
              :value="input.closes_at"
              picker-format="DD MM YYYY"
              @ionChange="changeClosesAt"
            />
          </ion-item>

          <ion-item>
            <ion-label text-wrap>
              Startdatum is anders dan verkoopdatum?
            </ion-label>
            <ion-toggle slot="end" :checked="startDateIsDifferent" @ionChange="toggleStartDateDifferent" />
          </ion-item>

          <ion-item v-if="startDateIsDifferent">
            <ion-label position="stacked">Startdatum abbonement</ion-label>

            <ion-datetime
              display-format="MMM DD, YYYY"
              :value="input.starts_at"
              picker-format="DD MM YYYY"
              @ionChange="changeStartsAt"
            />
          </ion-item>

          <ion-item>
            <ion-label position="stacked">
              Frequency
            </ion-label>
            <ion-select  :value="input.frequency" @ionChange="input.frequency = $event.target.value">
              <ion-select-option v-for="select in Object.values(frequencySelects)" :key="select.key" :value="select.key">
                {{ select.label }}
              </ion-select-option>
            </ion-select>
          </ion-item> -->

          <!-- <ion-item v-if="membershipsEnabled">
            <ion-label position="stacked">
              Is publicly orderable
            </ion-label>

            <ion-toggle :checked="input.is_publicly_orderable" @ionChange="input.is_publicly_orderable = !input.is_publicly_orderable" />
          </ion-item> -->

          <ion-item>
            <ion-label position="stacked">
              {{ $t('islive') }}
            </ion-label>

            <ion-toggle :checked="input.is_live" @ionChange="input.is_live = !input.is_live" />
          </ion-item>
        </ion-list>
      </ion-content>
      <ion-footer>
        <error :error="error" />
        <loading-button color="moto" :disabled="loading" :loading="loading" @click="createSubscription">
          {{ $t('tocreate') }}
        </loading-button>
      </ion-footer>
    </template>
  </base-modal>
</template>

<script>
import * as moment from 'moment';

import { CREATE_SUBSCRIPTION } from '../../graphql/mutations';
import LoadingButton from '../LoadingButton.vue';
import BaseModal from './BaseModal.vue';
import { FEATURE_FLAG_MAPPING } from '@/configs';
import { CURRENT_USER_GETTER } from "@/store/store-getters";

const startSellingSelects = {
  DIRECTLY: {
    key: 'DIRECTLY',
    label: 'Directly',
  },
  AT_START_DATE: {
    key: 'AT_START_DATE',
    label: 'At start date',
  }
};

const stopSellingSelects = {
  NEVER: {
    key: 'NEVER',
    label: 'Never',
  },
  AT_CLOSING_DATE: {
    key: 'AT_CLOSING_DATE',
    label: 'At closing date',
  }
};

const frequencySelects = {
  EVERY_YEAR: {
    key: 'EVERY_YEAR',
    label: 'Every year',
  }
};

export default {
  props: {
    facility_id: {
      type: String,
      required: true,
    },
  },
  components: {
    BaseModal,
    LoadingButton,
  },
  data() {
    return {
      error: null,
      loading: false,
      minOpensAt: moment().toISOString(true),
      minClosesAt: moment().add(1, 'day').toISOString(true),
      startDateIsDifferent: false,
      startSellingSelects,
      stopSellingSelects,
      frequencySelects,
      selectedSelling: startSellingSelects.DIRECTLY.key,
      selectedStopSelling: stopSellingSelects.NEVER.key,
      input: {
        name: '',
        frequency: frequencySelects.EVERY_YEAR.key,
        price: null,
        opens_at: null,
        closes_at: null,
        max_capacity: null,
        max_per_account: 1,
        is_publicly_orderable: false,
        is_live: false,
        starts_at: null,
      }
    }
  },
  computed: {
    membershipsEnabled() {
      const currentUser = this.$store.getters[CURRENT_USER_GETTER];
      return this.$optimizely.isFeatureEnabled(FEATURE_FLAG_MAPPING.MANUAL_MEMBERSHIPS, currentUser.id, {
        user_id: currentUser.id,
      });
    }
  },
  methods: {
    async createSubscription() {
      this.error = null;
      this.loading = true;

      try {
        await this.$apollo.mutate({
          mutation: CREATE_SUBSCRIPTION,
          variables: {
              input: this.input,
              facility_id: this.facility_id,
          }
        });

        await this.$parent.$apollo.queries.subscriptions.refetch();

        this.$ionic.modalController.dismiss();
      } catch(error) {
        this.error = error;
      }

      this.loading = false;
    },
    changeClosesAt(event) {
      this.input.closes_at = moment(event.target.value).format('YYYY-MM-DD');
    },
    changeOpensAt(event) {
      this.input.opens_at = moment(event.target.value).format('YYYY-MM-DD');

      this.minClosesAt = moment(event.target.value).add(1, 'day').toISOString(true);
    },
    changeStartsAt(event) {
      this.input.starts_at = moment(event.target.value).fomrat('YYYY-MM-DD');
    },
    changeSelectedSelling(event) {
      this.input.opens_at = null;

      this.selectedSelling = event.target.value;
    },
    changeSelectedStopSelling(event) {
      this.selectedStopSelling = event.target.value;
    },
    toggleStartDateDifferent() {
      this.input.starts_at = null;

      this.startDateIsDifferent = !this.startDateIsDifferent;
    }
  }
}
</script>
