<template>
  <ion-item>
    <ion-label color="white">
      <p>{{ member.user.first_name }} {{ member.user.last_name }}</p>
      <p>{{ member.user.email }}</p>
      <ion-badge color="warning" slot="end">
        {{ member.subscription.name }}
      </ion-badge>
      <ion-text v-if="member.is_expired" color="danger">
        <p>{{ $t("expiredat") }}: {{ member.expires_at }}</p>
      </ion-text>
      <ion-text v-else>
        <p>{{ $t("expiresat") }}: {{ member.expires_at }}</p>
      </ion-text>
    </ion-label>
  </ion-item>
</template>

<script>
  export default {
    props: ['member'],
  }
</script>