<template>
  <ion-list ref="slidingList">
    <ion-list-header>
      <ion-label>{{ $t('members') }}</ion-label>
      <div>
        <ion-button color="moto" size="small" @click="bulkEditMembers" v-if="facilityMembers && facilityMembers.length">
          {{ $t('bulkeditmembers') }}
        </ion-button>
        <ion-button color="moto" size="small" @click="addMember" :disabled="!canAddMember">
          {{ $t('addmember') }}
        </ion-button>
      </div>
    </ion-list-header>

    <ion-list-header v-if="facilityMembers && facilityMembers.length">
      <ion-label>{{ $t('sortby') }}:</ion-label>
      <ion-button @click="openPopOverSortOrder" class="left-auto">
        <ion-text>
          {{ selectedSortOrder.label }}
        </ion-text>
      </ion-button>
    </ion-list-header>
    <template v-else>
      <ion-item>
        <ion-label text-wrap>
          {{ $t('nofacilitymembersfound') }}
        </ion-label>
      </ion-item>
    </template>

    <ion-item-sliding v-for="member in facilityMembers" :key="member.id">
      <MemberItem :member="member" />
      <ion-item-options>
        <ion-item-option color="warning" @click="editFacilityMember(member)">
          <ion-icon name="create" /> {{ $t('editmembershipaction') }}
        </ion-item-option>
        <ion-item-option color="danger" @click="cancelMembership(member)">
          <ion-icon name="trash" /> {{ $t('cancelmembership') }}
        </ion-item-option>
      </ion-item-options>
    </ion-item-sliding>
  </ion-list>
</template>


<script>
import AddMemberModal from '@/components/modals/AddMemberModal.vue';
import { GET_FACILITY_MEMBERS, GET_FACILITY_SUBSCRIPTIONS } from '../../../graphql/queries';
import { CANCEL_USER_MEMBERSHIP } from '../../../graphql/mutations';
import PopOverMemberSortOrder from "@/components/popovers/PopOverMemberSortOrder";
import { sortBy } from "lodash";
import EditFacilityMemberModal from "@/components/modals/EditFacilityMemberModal";
import MemberItem from "@/components/items/MemberItem.vue";

export default {
  components: {MemberItem},
  props: {
    id: {
      type: [String, undefined],
      default: undefined
    },
    facility: {
      type: [Object, undefined],
      default: undefined
    }
  },
  data() {
    const sortOrders = [
      {
        label: this.$t("sortbyname"),
        key: "NAME"
      },
      {
        label: this.$t("sortbyemail"),
        key: "EMAIL"
      },
      {
        label: this.$t("sortbysubscribedat"),
        key: "SUBSCRIBED_AT"
      },
      {
        label: this.$t("sortbyexpiresat"),
        key: "EXPIRES_AT"
      },
      {
        label: this.$t("sortbysubscription"),
        key: "SUBSCRIPTION"
      },
    ];

    return {
      categorySortOrderModal: undefined,
      selectedSortOrder: sortOrders[0],
      sortOrders,
    }
  },
  computed: {
    canAddMember() {
      return !!this.facility?.enabled_subscriptions?.length;
    },
    facilityMembers() {
      return sortBy(this.getFacilityMembers, [(facilityMember) => {
        switch (this.selectedSortOrder.key) {
          case 'EMAIL':
            return facilityMember.user.email;
          case 'SUBSCRIBED_AT':
            return facilityMember.became_member_at;
          case 'EXPIRES_AT':
            return facilityMember.expires_at;
          case 'SUBSCRIPTION':
            return facilityMember.subscription.name;
          default:
            return facilityMember.user.last_name;
        }
      }]);
    }
  },
  methods: {
    bulkEditMembers() {
      this.$router.push({name: this.$routeNames.FACILITY_MEMBERS_BULK_EDIT, params: {id: this.id}});
    },
    async openPopOverSortOrder(event) {
      const p = await this.$ionic.popoverController.create({
        component: PopOverMemberSortOrder,
        componentProps: {
          parent: this,
          propsData: {
            activeItem: this.selectedSortOrder,
            items: this.sortOrders,
            selectItem: this.selectSortOrder,
            dismiss: this.dismissPopoverSortOrder
          }
        },
        translucent: false,
        event
      });

      this.categorySortOrderModal = p;

      await p.present();
    },
    selectSortOrder(sortOrder) {
      this.selectedSortOrder = sortOrder;
    },
    dismissPopoverSortOrder() {
      this.categorySortOrderModal?.dismiss();
    },
    async addMember() {
      const modal = await this.$ionic.modalController.create({
        component: AddMemberModal,
        componentProps: {
          parent: this,
          propsData: {
            facility_id: this.id,
          }
        }
      });

      await modal.present();
    },
    async editFacilityMember(facilityMember) {
      const modal = await this.$ionic.modalController.create({
        component: EditFacilityMemberModal,
        componentProps: {
          parent: this,
          propsData: {
            facilityMember,
            facility_id: this.id,
          }
        }
      });

      await modal.present();
      await this.$refs.slidingList.closeSlidingItems();
    },
    async cancelMembership(member) {
      await this.$refs.slidingList.closeSlidingItems();

      try {
        await this.$apollo.mutate({
          mutation: CANCEL_USER_MEMBERSHIP,
          variables: {
            user_id: member.user.id,
            facility_id: this.id,
          }
        });

         const toast = await this.$ionic.toastController.create({
          color: 'success',
          message: 'Membership cancelled successfully',
          showCloseButton: true,
        });

        await toast.present();
        this.$apollo.queries.getFacilityMembers.refetch();
      } catch (error) {
        const toast = await this.$ionic.toastController.create({
          color: 'danger',
          message: error?.message,
          showCloseButton: true,
        });

        await toast.present();
      }
    }
  },
  apollo: {
    subscriptions: {
      query: GET_FACILITY_SUBSCRIPTIONS,
      variables() {
        return {
          facility_id: this.id,
        }
      },
      update: data => data.getFacilitySubscriptions
    },
    getFacilityMembers: {
      query: GET_FACILITY_MEMBERS,
      variables() {
        return {
          facility_id: this.id
        }
      }
    }
  }
}
</script>