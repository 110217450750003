<template>
  <div>
    <List
      :header="$t('upcomingeventstitle')"
      :not-found-message="$t('noupcomingeventslabel')"
    >
      <template v-slot:top>
        <UpcomingEventSelectionFilter @change="changeFilter" />
      </template>
      <template v-if="events && events.data && events.data.length">
        <div v-for="event in events.data" :key="event.id" @click="openSingleEvent(event.id)">
          <EventItem :event="event" button />
        </div>
        <ion-item v-if="events.paginatorInfo.hasMorePages" @click="openUpcomingEvents" button>
          <ion-label class="text-center">
            <span class="text-sm font-bold flex items-center flex-1 justify-center">{{ $t('showall') }} <ion-icon name="arrow-forward" /></span>
          </ion-label>
        </ion-item>
      </template>
    </List>
  </div>
</template>

<script>
  import { GET_UPCOMING_FACILITY_EVENTS } from '@/graphql/queries';
  import EventItem from '@/components/items/EventItem';
  import { defaultPaginationConfig } from '@/configs';
  import UpcomingEventSelectionFilter from "@/components/items/UpcomingEventSelectionFilter";

  export default {
    components: {
      UpcomingEventSelectionFilter,
      EventItem
    },
    data() {
      return {
        filterBy: 'PLANNED'
      }
    },
    props: ['id', 'userIsAdmin'],
    methods: {
      openSingleEvent(id) {
        this.$router.push({name: this.$routeNames.EVENT, params: {id}})
      },
      openUpcomingEvents() {
        this.$router.push({name: this.$routeNames.FACILITY_UPCOMING_EVENTS, params: {id: this.id}})
      },
      changeFilter(filterOption) {
        this.filterBy = filterOption.key
      }
    },
    apollo: {
      events: {
        query: GET_UPCOMING_FACILITY_EVENTS,
        fetchPolicy: 'network-only',
        variables() {
          return {
            filter_by: this.filterBy,
            facility_id: this.id,
            ...defaultPaginationConfig,
            first: 5,
          }
        },
        update: (data) => data.getUpcomingFacilityEvents
      }
    }
  }
</script>
