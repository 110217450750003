<template>
  <ion-col size="6" size-sm="4" size-lg="auto" no-padding>
    <ion-button size="small" expand="full" :color="isActive ? 'moto' : 'secondary'" @click.prevent="$emit('click')">
      {{ label }}
    </ion-button>
  </ion-col>
</template>

<script>
  export default {
    props: {
      isActive: {
        type: Boolean,
        default: false
      },
      label: {
        type: String,
        required: true
      }
    },
  }
</script>