<template>
  <ion-list>
    <ion-list-header>
      <ion-label>{{ $t('advertisements') }}</ion-label>
    </ion-list-header>

    <template v-if="!ads || !ads.length">
      <ion-item>
        <ion-label text-wrap>
          {{ $t('noadvertisements') }}
        </ion-label>
      </ion-item>
    </template>

    <template v-for="ad in ads" >
      <AdItem button :ad="ad" :key="ad.id" @click.native="openAdvertisement(ad)" />
    </template>
  </ion-list>
</template>

<script>
import { GET_FACILITY_ADS } from '@/graphql/queries';
import { routeNames } from "@/router/config";
import AdItem from "../../../components/items/AdItem";

export default {
  components: {AdItem},
  props: {
    id: {
      type: String,
      required: true,
    }
  },
  methods: {
    openAdvertisement(ad) {
      this.$router.push({ name: routeNames.FACILITY_ADVERTISEMENT, params: { id: this.id, advertisementId: ad.id }})
    }
  },
  apollo: {
    ads: {
      query: GET_FACILITY_ADS,
      variables() {
        return {
          facility_id: this.id,
        }
      },
      update: data => data.getAdsByFacility
    }
  }
}
</script>