<template>
  <ion-list ref="slidingList">
    <ion-list-header>
      <ion-label>{{ $t('subscriptions') }}</ion-label>
      <ion-button color="moto" size="small" @click="addSubscription">
        {{ $t('tocreate') }}
      </ion-button>
    </ion-list-header>

    <template v-if="!subscriptions || !subscriptions.length">
      <ion-item>
        <ion-label text-wrap>
          {{ $t('nofacilitysubscriptionsfound') }}
        </ion-label>
      </ion-item>
    </template>

    <ion-item-sliding v-for="subscription in subscriptions" :key="subscription.id">
      <ion-item>
        <ion-label>
          <div>{{ subscription.name }}</div>
          <ion-badge color="moto">{{ subscription.price | stripeAmount }}</ion-badge>
        </ion-label>
        <ion-badge v-if="!subscription.is_live" color="warning" slot="end">{{ $t('concept') }}</ion-badge>
      </ion-item>

      <ion-item-options>
        <ion-item-option color="danger" @click="removeSubscription(subscription.id)">
          <ion-icon name="trash" /> {{ $t('removesubscription')}}
        </ion-item-option>
      </ion-item-options>
    </ion-item-sliding>
  </ion-list>
</template>

<script>
import AddSubscriptionModal from '../../../components/modals/AddSubscriptionModal.vue'
import { REMOVE_SUBSCRIPTION } from '../../../graphql/mutations';
import { GET_FACILITY_SUBSCRIPTIONS } from '../../../graphql/queries';
export default {
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  apollo: {
    subscriptions: {
      query: GET_FACILITY_SUBSCRIPTIONS,
      variables() {
          return {
            facility_id: this.id,
          }
      },
      update: data => data.getFacilitySubscriptions
    }
  },
  methods: {
    async removeSubscription(id) {
       await this.$refs.slidingList.closeSlidingItems();

      try {
        await this.$apollo.mutate({
          mutation: REMOVE_SUBSCRIPTION,
          variables: {
            id: id,
            facility_id: this.id,
          }
        });

        const toast = await this.$ionic.toastController.create({
          color: 'success',
          message: this.$t('subscriptionremovedsuccessfully'),
          duration: 3000,
          showCloseButton: true,
        });

        await toast.present();
      } catch (error) {
        const toast = await this.$ionic.toastController.create({
          color: 'danger',
          message: error?.message,
          showCloseButton: true,
        });

        await toast.present();
      } finally {
        await this.$apollo.queries.subscriptions.refetch();
        this.$emit('change');
      }
    },
    async addSubscription() {
      const modal = await this.$ionic.modalController.create({
        component: AddSubscriptionModal,
        componentProps: {
          parent: this,
          propsData: {
            facility_id: this.id,
          },
        }
      });

      modal.onDidDismiss().then( () => {
        this.$emit('change');
      })

      await modal.present();
    }
  }
}
</script>
