<template>
  <div>
    <PageItem>
      <div>
        <h3 class="font-bold ">
          {{ $t('info') }}
        </h3>
      </div>

      <div v-if="!facility.website && !facility.phone && !facility.email" class="my-5">
        <p>{{ $t('noinformationadded') }}</p>
      </div>

      <div v-else class="my-5">
        <p v-if="validFacilityWebsite">
          <a :href="validFacilityWebsite" target="_blank" :rel="SAFE_LINK_REL"><ion-icon name="link" /> {{ facility.website }}</a>
        </p>

        <p v-if="facility.email">
          <a :href="`mailto:${facility.email}`" :rel="SAFE_LINK_REL"><ion-icon name="mail" /> {{ facility.email }}</a>
        </p>

        <p v-if="facility.phone">
          <a :href="`tel:${facility.phone}`" :rel="SAFE_LINK_REL"><ion-icon name="call" /> {{ facility.phone }}</a>
        </p>
      </div>

      <div v-if="userIsAdmin">
        <ion-button expand="full" size="small" @click="informationModal">
          {{ $t('updateinformation') }}
        </ion-button>
      </div>
    </PageItem>

    <PageItem v-if="facility.description || userIsAdmin">
      <h3 class="font-bold">
        {{ $t('description') }}
      </h3>

      <ion-textarea v-if="userIsAdmin" :placeholder="$t('addadescription')" :value="facility.description" @ionInput="facility.description = $event.target.value" />
      <p v-else class="whitespace-pre-wrap">
        {{ facility.description }}
      </p>

      <ion-button v-if="userIsAdmin" expand="full" class="text-center" size="small" @click="saveDescription">
        {{ $t('save') }}
      </ion-button>
      <ion-text v-if="descriptionSaved">
        <p>{{ $t('descriptionsaved') }}</p>
      </ion-text>
    </PageItem>
  </div>
</template>

<script>
  import InformationModal from "@/components/modals/InformationModal";
  import {UPDATE_FACILITY} from "@/graphql/mutations";
  import PageItem from '@/components/PageItem';
  import { CURRENT_USER_GETTER } from "@/store/store-getters";
  import { mapGetters } from "vuex";
  import { SAFE_LINK_REL, validWebsiteURL } from "@/utils";

  export default {
      components: {
          PageItem
      },
      props: {
        facilityProp: {
          type: [Object, undefined],
          default: undefined
        }
      },
      data() {
        return {
          SAFE_LINK_REL,
          facility: null,
          descriptionSaved: false,
        }
      },
      computed: {
        ...mapGetters({user: CURRENT_USER_GETTER}),
        userIsAdmin() {
          return this.$gate.can('edit', 'facilities', this.facility);
        },
        validFacilityWebsite() {
          return this.validWebsiteURL(this.facility?.website);
        }
      },
      created() {
        this.facility = this.facilityProp
      },
    mounted() {
      this.$on('updateFacilityInformation', (facility) => {
        this.facility.website = facility.website
        this.facility.phone = facility.phone
        this.facility.email = facility.email
      })
    },
      methods: {
        validWebsiteURL,
        openTab(tabname) {
          this.$parent.$emit('openTab', tabname);
        },
        saveDescription() {
          this.$apollo.mutate({
            mutation: UPDATE_FACILITY,
            variables: {
              id: this.$route.params.id,
              input: {
                description: this.facility.description
              }
            },
          }).then((response) => {
            if (response && response.data && response.data.updateFacility) {
              this.facility.description = response.data.updateFacility.description
              this.descriptionSaved = true;
            }
          })
        },
        async informationModal() {
          try {
            const modal = await this.$ionic.modalController.create({
              component: InformationModal,
              componentProps: {
                parent: this
              }
            });

            return modal.present();
          } catch(error) {
            // eslint-disable-next-line no-console
            console.error(error);
          }
        },
      }
    }
</script>

