<template>
  <List
    :header="$t('transfers')"
    :loading="$apollo.loading && !getOrderTransfers"
    :loading-more="loadingMore"
    :not-found-message="$t('notransfersfound')"
    @loadMore="loadMore($event, 'getOrderTransfers')"
  >
    <template v-if="getOrderTransfers && getOrderTransfers.data && getOrderTransfers.data.length">
      <ion-item v-for="transfer in getOrderTransfers.data" :key="transfer.id" button @click="openTransfer(id, transfer.id)">
        <ion-grid>
          <ion-row>
            <ion-col>
              <ion-text color="white" text-wrap>
                <p>{{ transfer.title }}</p>
              </ion-text>
              <ion-label color="moto"><h2>{{ transfer.stripe_amount | stripeAmount }}</h2></ion-label>
              <ion-text>
                <p>{{ $t('transferredon') }} {{ transfer.created_at|moment('D MMM YYYY') }}</p>
              </ion-text>
            </ion-col>
            <ion-col size="2" class="ion-text-right">
              <ion-badge>{{ transfer.type }}</ion-badge>
              <ion-badge color="moto" class="ml-1">{{ transfer.status }}</ion-badge>
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-item>
    </template>
  </List>
</template>

<script>
  import { GET_ORDER_TRANSFERS } from '@/graphql/queries';
  import { stripeAmount } from "../../../utils";
  import { infiniteScrollMixin } from "@/mixins";
  import { defaultPaginationConfig } from "@/configs";

export default {
  props: ['id'],
  mixins: [infiniteScrollMixin],
  apollo: {
    getOrderTransfers: {
      query: GET_ORDER_TRANSFERS,
      fetchPolicy: 'network-only',
      variables() {
        return {
          ...defaultPaginationConfig,
          facility_id: this.id
        }
      },
    }
  },
  methods: {
    stripeAmount,
    openTransfer(facilityId, transferId) {
      this.$router.push({ name: this.$routeNames.FACILITY_TRANSFERS, params: { id: facilityId, transferId } })
    },
    async doRefresh(event, model) {
      this.resetInfiniteScroll();
      await this.$apollo.queries[model].refetch();
      event.target.complete()
    },
  }
}
</script>
