<template>
  <div>
    <ion-toolbar v-if="userIsAdmin">
        <ion-button class="float-right" color="moto" size="small" @click="createEvent">{{ $t('tocreate') }}</ion-button>
        <ion-button
          v-if="canExportEventAttendees"
          :disabled="exporting"
          class="float-right"
          color="moto"
          size="small"
          @click="exportEventAttendees">
          {{ $t('toexportattendees') }}
        </ion-button>
    </ion-toolbar>

    <upcoming-events :id="id" :userIsAdmin="userIsAdmin"/>
    <passed-events :id="id" />
  </div>
</template>

<script>
    import UpcomingEvents from './events/UpcomingEvents';
    import PassedEvents from './events/PassedEvents';
    import { CURRENT_USER_GETTER } from "@/store/store-getters";
    import { FEATURE_FLAG_MAPPING } from "@/configs";
    import SelectFacilityEventsModal from "@/components/modals/SelectFacilityEventsModal";
    import { EXPORT_MULTI_EVENT_ATTENDEES } from "@/graphql/mutations";
    import { showToast, TOAST_DANGER } from "@/utils";

    export default {
      components: {
          UpcomingEvents,
          PassedEvents,
      },
      props: ['id', 'userIsAdmin'],
      data() {
        return {
          exporting: false,
        }
      },
      computed: {
        canExportEventAttendees() {
          if (!this.userIsAdmin) {
            return false;
          }
          const currentUser = this.$store.getters[CURRENT_USER_GETTER];
          return this.$optimizely.isFeatureEnabled(FEATURE_FLAG_MAPPING.EXPORT_MULTI_EVENT_ATTENDEES, currentUser.id, {
            user_id: currentUser.id,
            facility_id: this.id
          });
        }
      },
      methods: {
        showToast,
        createEvent() {
          this.$router.push({name: this.$routeNames.CREATE_EVENT});
        },
        chooseEvents() {
          return new Promise( async (resolve) => {
            const modal = await this.$ionic.modalController.create({
              component: SelectFacilityEventsModal,
              componentProps: {
                parent: this,
                propsData: {
                  limit: 10,
                  buttonLabel: this.$t('toexportattendees'),
                  facility_id: this.id,
                  onSubmit: (selection) => {
                    resolve(selection);
                    modal.dismiss();
                  },
                }
              }
            });
            modal.onDidDismiss().then( () => resolve(false) );
            await modal.present();
          })
        },
        async exportMultiEventAttendees(selection) {
          this.exporting = true;
          try {
            const response = await this.$apollo.mutate({
              mutation: EXPORT_MULTI_EVENT_ATTENDEES,
              variables: {
                facility_id: this.id,
                event_ids: selection.map( item => item.id ),
              }
            })

            await this.showToast(this.$t('exportready'))
            this.$emit('exportReady', response.data.exportMultiEventAttendees);
          } catch (err) {
            await this.showToast( err.message.replace('GraphQL error:', '').trim(), TOAST_DANGER );
          } finally {
            this.exporting = false;
          }
        },
        async exportEventAttendees() {
          const selection = await this.chooseEvents();

          if (!selection || !selection.length) {
            return
          }

          await this.exportMultiEventAttendees(selection);
        }
      }
    }
</script>
