<template>
  <base-modal :title="$t('topup')" @close="close">
    <ion-content>
      <div class="ion-padding">
        <ion-text v-html="$t('topupinformation')"></ion-text>
      </div>
      <ion-list>
        <ion-list-header>
          {{ $t('amount') }}
        </ion-list-header>
        <ion-item>
          <ion-text class="text-3xl" slot="start">€</ion-text>
          <ion-input
            class="text-3xl"
            type="number"
            :placeholder="'0.00'"
            :value="amount_in_euros"
            @ionChange="amount_in_euros = $event.target.value" />
        </ion-item>
      </ion-list>

      <ion-list>
        <ion-list-header>
          {{ $t('description') }}
        </ion-list-header>
        <ion-item>
          <ion-input
            type="text"
            :placeholder="$t('enteradescription')"
            :value="description"
            @ionChange="description = $event.target.value"
          />
        </ion-item>
      </ion-list>
    </ion-content>
    <ion-footer>
      <Error :error="error" />

      <ion-button
        color="moto"
        expand="full"
        :disabled="checkoutButtonDisabled"
        @click="checkoutWithStripe"
      >
        <ion-label>{{ $t('topup') }}</ion-label>
      </ion-button>
    </ion-footer>
  </base-modal>
</template>

<script>
  import { TOP_UP_BALANCE, UPDATE_PAYMENT_METHOD_FOR_ORDER } from "@/graphql/mutations";
  import { get } from "lodash";
  import { logoutAndRedirect } from '@/utils/logoutAndRedirect';
  import { hasAuthError } from '@/utils/hasAuthError';
  import BaseModal from './BaseModal.vue';
  import { Browser } from '@capacitor/browser';
  import { Capacitor } from '@capacitor/core';
  import { mapGetters } from "vuex";
  import { CURRENT_USER_GETTER } from "@/store/store-getters";
  import { priceAmount, stripeFee } from "@/utils";
  import { STRIPE_API_KEY } from "@/configs";
  import StripeCheckout from "@/components/modals/StripeCheckout";

  export default {
    components: {
      BaseModal,
    },
    props: ['facility_id', 'closeMe'],
    data() {
      return {
        description: this.$t('topupdefaultdescription'),
        amount_in_euros: 100,
        loading: false,
        error: null,
      }
    },
    computed: {
      ...mapGetters({user: CURRENT_USER_GETTER}),

      checkoutButtonDisabled() {
        return (!this.amount_in_euros) || this.loading;
      },

      amount_in_cents() {
        if (!this.amount_in_euros) {
          return 0;
        }
        return parseInt(this.amount_in_euros * 100);
      }
    },

    methods: {
      priceAmount,
      stripeFee,

      lineItems() {
        return [
          {
            name: this.description,
            price: this.amount_in_euros,
            quantity: 1,
          }
        ]
      },

      async openStripeCheckoutModal() {
        const p = new Promise(async (resolve) => {
          const propsData = {
            closeMe: null,
            line_items: this.lineItems(),
            user: this.user,
            confirm: (paymentMethod) => {
              modal.dismiss();
              resolve(paymentMethod);
            }
          };

          const modal = await this.$ionic.modalController
            .create({
              component: StripeCheckout,
              componentProps: {
                parent: this,
                propsData
              },
            });

          propsData.closeMe = () => {
            modal.dismiss();
            resolve(false);
          };

          return modal.present();
        });
        return p;
      },

      createOrder() {
        return this.$apollo.mutate({
          mutation: TOP_UP_BALANCE,
          variables: {
            facility_id: this.facility_id,
            data: {
              amount: this.amount_in_cents,
              description: this.description,
            }
          },
        })
      },

      updatePaymentMethodForOrder(order_id, payment_method) {
        return this.$apollo.mutate({
          mutation: UPDATE_PAYMENT_METHOD_FOR_ORDER,
          variables: {
            id: order_id,
            payment_method,
          },
        }).then((result) => {
          return result.data.updatePaymentMethodForOrder
        });
      },

      async checkoutWithStripe() {
        this.loading = true;
        this.error = null;

        // Additional-fields have errors
        if (this.error) {
          this.loading = false;
          return;
        }

        try {
          const data = await this.createOrder();

          if (get(data, 'errors[0].extensions.category') === 'authentication') {
            this.close()

            await logoutAndRedirect();
            return;
          }

          let result = data.data.topUpBalance;

          const paymentMethod = await this.openStripeCheckoutModal();

          if (!paymentMethod) {
            return;
          } else {
            result = await this.updatePaymentMethodForOrder(result.order_id, paymentMethod);
          }

          return this.openStripeBrowserSession(result.session_id);
        } catch (error) {

          if (hasAuthError(error)) {
            this.close();
            await logoutAndRedirect();
          }

          this.error = error;
        } finally {
          this.loading = false;
        }

      },
      async openStripeBrowserSession(sessionId) {

        if (Capacitor.isNativePlatform()) {
          await Browser.open({url: `${process.env.VUE_APP_API_URL}/redirect-to-stripe?session_id=${sessionId}`});
          return;
        }

        const stripe = window.Stripe(STRIPE_API_KEY);

        stripe.redirectToCheckout({
          // Make the id field from the Checkout Session creation API response
          // available to this file, so you can provide it as parameter here
          // instead of the {{CHECKOUT_SESSION_ID}} placeholder.
          sessionId: sessionId
        }).then(function (result) {
          // If `redirectToCheckout` fails due to a browser or network
          // error, display the localized error message to your customer
          // using `result.error.message`.

          // eslint-disable-next-line no-console
          console.log(result.error.message)
        });
      },
      close() {
        this.closeMe();
      }
    }

  }
</script>
