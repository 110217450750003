<template>
  <List
    :header="'Payouts'"
    :loading="$apollo.loading && !getPayouts"
    :not-found-message="`No payouts yet`"
  >
    <template v-if="getPayouts && getPayouts.length">
      <ion-item v-for="payout in getPayouts" :key="payout.id" button @click="openPayout(id, payout.id)">
        <ion-label color="white" text-wrap>
          <h2>{{ getAmount(payout.amount, payout.currency) }}</h2>
          <p>{{ $t('depositedon') }} {{ payout.arrival_date|moment('D MMM YYYY') }}</p>
        </ion-label>
        <ion-badge slot="end" color="moto">{{ payout.status }}</ion-badge>
      </ion-item>
    </template>
  </List>
</template>

<script>
import { GET_PAYOUTS } from '@/graphql/queries';
import Dinero from 'dinero.js';

export default {
  props: ['id'],
  apollo: {
    getPayouts: {
      query: GET_PAYOUTS,
      variables() {
        return {
          facility_id: this.id
        }
      }
    }
  },
  methods: {
    openPayout(facilityId, payoutId) {
      this.$router.push({ name: this.$routeNames.FACILITY_PAYOUTS, params: { id: facilityId, payoutId } })
    },
    getAmount(amount, currency) {
      return Dinero({ amount, currency }).setLocale(navigator.language).toFormat('$0,0.00');
    }
  }
}
</script>
