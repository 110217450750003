<template>
  <ion-page class="ion-page">
    <ContentContainer v-if="facility">
      <Refresher @refresh="doRefresh($event)" />

      <div :style="{ backgroundImage: `url('${$apiStorageUrl}/${ facility.profile_image }` }" style="height: 30vh; background-size: cover; background-position:center;" />

      <PageItem class="no-margin-top">
        <div>
          <h1 class="text-center text-2xl font-light">
            {{ facility.name }}
          </h1>
          <div class="text-center mt-2">
            <p v-if="facility.facility_type.name == 'CLUB'">
              {{ $t('club') }}
            </p>
            <p v-if="facility.facility_type.name == 'SHOP'">
              {{ $t('shop') }}
            </p>
            <p v-if="facility.facility_type.name == 'TRAINER'">
              {{ $t('trainer') }}
            </p>
          </div>
          <div class="text-center mt-2">
            <ion-badge v-if="userIsAdmin" color="moto">
              <ion-icon name="key" color="primary" /> {{ $t('admin') }}
            </ion-badge>
          </div>

          <div class="flex">
            <ion-button
              v-if="userIsAdmin"
              size="small"
              class="flex-1"
              color="moto"
              expand="full"
              @click="editFacility">
              {{ $t('editfacility') }}
            </ion-button>
            <ion-button v-if="userIsAdmin" size="small" color="secondary" @click="openPopover">
              <ion-icon name="more" color="moto" />
            </ion-button>
          </div>

          <!-- <ion-button v-if="facility.public_subscriptions && membershipsEnabled" @click="openSubscriptionModal" size="small" color="moto" expand="full">
            Become a member
          </ion-button> -->
        </div>

        <ion-segment class="mt-10">
          <ion-grid>
            <ion-row>
              <TabButton :label="$t('info')" :is-active="tab === tabs.INFO" @click="tab = tabs.INFO" />
              <TabButton :label="$t('events')" :is-active="tab === tabs.EVENTS" @click="tab = tabs.EVENTS" />
              <TabButton v-if="userIsAdmin && membershipsEnabled" :label="$t('subscriptions')" :is-active="tab === tabs.SUBSCRIPTIONS" @click="tab = tabs.SUBSCRIPTIONS" />
              <TabButton v-if="userIsAdmin && membershipsEnabled" :label="$t('members')" :is-active="tab === tabs.MEMBERS" @click="tab = tabs.MEMBERS" />
              <TabButton v-if="userIsAdmin && payoutsEnabled()" :label="$t('payouts')" :is-active="tab === tabs.PAYOUTS" @click="tab = tabs.PAYOUTS" />
              <TabButton v-if="userIsAdmin && transfersEnabled()" :label="$t('transfers')" :is-active="tab === tabs.TRANSFERS" @click="tab = tabs.TRANSFERS" />
              <TabButton v-if="userIsAdmin" :label="$t('advertisements')" :is-active="tab === tabs.ADVERTISEMENTS" @click="tab = tabs.ADVERTISEMENTS" />
              <TabButton v-if="userIsAdmin" :label="$t('topups')" :is-active="tab === tabs.TOP_UPS" @click="tab = tabs.TOP_UPS" />
              <TabButton v-if="userIsAdmin" :label="$t('admins')" :is-active="tab === tabs.ADMINS" @click="tab = tabs.ADMINS" />
            </ion-row>
          </ion-grid>
        </ion-segment>
      </PageItem>

      <InfoTab v-if="tab === tabs.INFO" :facility-prop="facility" />

      <Events v-if="tab === tabs.EVENTS" :id="facility.id" :user-is-admin="userIsAdmin" @exportReady="$emit('exportReady', $event)"/>

      <Payouts v-if="tab === tabs.PAYOUTS" :id="facility.id" />

      <Transfers v-if="tab === tabs.TRANSFERS" :id="facility.id" />

      <Subscriptions v-if="tab === tabs.SUBSCRIPTIONS" :id="facility.id"  @change="refreshFacility" />

      <Members v-if="tab === tabs.MEMBERS" :id="facility.id" :facility="facility" />

      <Advertisements v-if="tab === tabs.ADVERTISEMENTS" :id="facility.id" />

      <div v-if="tab == tabs.ADMINS">
        <ion-list lines="full">
          <ion-list-header>
            <ion-label>{{ $t('admins') }}</ion-label>
          </ion-list-header>

          <ion-item v-for="admin in facility.admins" :key="admin.id" class="flex justify-between" button @click="editAdmin(admin)">
            <ion-label color="white">
              <p>{{ admin.first_name }} {{ admin.last_name }}</p>
              <p>{{ admin.email }}</p>
            </ion-label>
          </ion-item>
          <ion-item>
            <ion-button size="small" @click="openAdminModal">
              {{ $t('addadmin') }}
            </ion-button>
          </ion-item>
        </ion-list>
      </div>

      <TopUps v-if="tab === tabs.TOP_UPS" :id="facility.id" :facility="facility" />

    </ContentContainer>

    <ion-footer v-if="exportUrl && userIsAdmin">
      <ion-toolbar>
        <ion-button color="moto" expand="full" :href="exportUrl">
          {{ $t('downloadexport') }}
        </ion-button>
      </ion-toolbar>
    </ion-footer>

  </ion-page>
</template>

<script>
  import PageItem from '@/components/PageItem';
  import Modal from '@/components/AdminModal.vue'
  import { GET_FACILITY } from "@/graphql/queries";
  import MotoPopOver from '@/mixins/PopOver'
  import InfoTab from '@/views/facilities/tabs/Info';
  import Events from '@/views/facilities/tabs/Events';
  import Payouts from '@/views/facilities/tabs/Payouts';
  import Members from '@/views/facilities/tabs/Members';
  import Subscriptions from '@/views/facilities/tabs/Subscriptions';
  import EditAdminModal from '@/components/modals/EditAdminModal.vue';
  import { FEATURE_FLAG_MAPPING } from '@/configs';
  import ChooseSubscriptionModal from '@/components/modals/ChooseSubscriptionModal.vue';
  import { CURRENT_USER_GETTER } from "@/store/store-getters";
  import Transfers from "@/views/facilities/tabs/Transfers.vue";
  import EditFacilityPopOver from "@/components/popovers/EditFacilityPopOver.vue";
  import TabButton from "@/components/buttons/TabButton.vue";
  import Advertisements from './tabs/Advertisements.vue';
  import TopUps from "@/views/facilities/tabs/TopUps";

  const tabs = {
    EVENTS: 'EVENTS',
    ADMINS: 'BEHEERDERS',
    PAYOUTS: 'PAYOUTS',
    TRANSFERS: 'TRANSFERS',
    SUBSCRIPTIONS: 'SUBSCRIPTIONS',
    INFO: 'INFO',
    ADVERTISEMENTS: 'ADVERTISEMENTS',
    MEMBERS: 'MEMBERS',
    TOP_UPS: 'TOP_UPS',
  }

  export default {
    components: {
    TabButton,
    TopUps,
    Transfers,
    PageItem,
    Subscriptions,
    Members,
    InfoTab,
    Events,
    Payouts,
    Advertisements
},
    mixins: [MotoPopOver],
    data() {
      return {
        exportUrl: null,
        description: '',
        tab: 'INFO',
        tabs,
      }
    },
    computed: {
      userIsAdmin() {
        return this.$gate.can('edit', 'facilities', this.facility);
      },
      membershipsEnabled() {
        const currentUser = this.$store.getters[CURRENT_USER_GETTER];
        return this.$optimizely.isFeatureEnabled(FEATURE_FLAG_MAPPING.MANUAL_MEMBERSHIPS, currentUser.id, {
          user_id: currentUser.id,
        });
      }
    },
    mounted() {
      this.$on('exportReady', (value) => {
        this.popover?.dismiss();

        this.exportUrl = value
      });

      this.$on('openTab', tabname => {
        this.tab = tabname;
      });
    },
    methods: {
      tabColor(tabName) {
        return (this.tab === tabName) ? 'moto' : 'secondary';
      },
      editFacility() {
        this.$router.push({ name: this.$routeNames.EDIT_FACILITY, params: { id: this.facility.id } })
      },
      async editAdmin(admin) {
        const modal = await this.$ionic.modalController.create({
          component: EditAdminModal,
          componentProps: {
            parent: this,
            propsData: {
              facility: this.facility,
              admin: admin
            }
          },
        });

        modal.present();
      },
      payoutsEnabled() {
        return this.$optimizely.isFeatureEnabled(FEATURE_FLAG_MAPPING.CAN_SEE_PAYOUTS, this.$store.getters[CURRENT_USER_GETTER].id, {
          facility_id: this.facility.id
        });
      },
      transfersEnabled() {
        return this.$optimizely.isFeatureEnabled(FEATURE_FLAG_MAPPING.CAN_SEE_TRANSFERS, this.$store.getters[CURRENT_USER_GETTER].id, {
          facility_id: this.facility.id
        });
      },
      refreshFacility() {
        this.$apollo.queries.facility.refetch();
      },
      doRefresh(event) {
        this.$apollo.queries.facility.refetch().then(() => {
          event.target.complete()
        })
      },
      async openAdminModal()
      {
        const modal = await this.$ionic.modalController.create({
          component: Modal,
          componentProps: {
            parent: this,
            propsData: {
              admins: this.facility.admins,
              facility: this.facility,
              closeMe: () => {
                this.$apollo.queries.facility.refetch()
                this.$ionic.modalController.dismiss()
              }
            },
          },
        });

        modal.present();
      },
      async openSubscriptionModal() {
        const modal = await this.$ionic.modalController.create({
          component: ChooseSubscriptionModal,
          componentProps: {
            parent: this,
          }
        });

        await modal.present();
      },
      async openPopover(event) {
        try {
          const popOver = await this.$ionic.popoverController.create({
            component: EditFacilityPopOver,
            componentProps: {
              propsData: {
                facilityId: this.facility.id,
              },
              parent: this,
            },
            translucent: false,
            event
          })

          popOver.present();
          this.popover = popOver;
        } catch(err) {
          // eslint-disable-next-line no-console
          console.error(err);
        }
      },
      async showToast(message, color = 'success', duration = 3000) {
        const toast = await this.$ionic.toastController.create({
          color: color,
          duration: duration,
          message: message
        });

        await toast.present();
      }
    },
    apollo: {
      facility: {
        query: GET_FACILITY,
        fetchPolicy: 'no-cache', // TODO: Find out why we need this.
        variables()  {
          return {
            id: this.$route.params.id,
          }

        },
      }
    }
  }
</script>

