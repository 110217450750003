<template>
  <base-modal :title="$t('updateinformation')" @close="close">
    <ion-content>
      <ion-list>
        <ion-item>
          <ion-label position="stacked">
            {{ $t('website') }}
          </ion-label>
          <ion-input type="url" :value="website" @ionInput="website = $event.target.value" />
        </ion-item>

        <ion-item>
          <ion-label position="stacked">
            {{ $t('phone') }}
          </ion-label>
          <ion-input type="tel" :value="phone" @ionInput="phone = $event.target.value" />
        </ion-item>

        <ion-item>
          <ion-label position="stacked">
            {{ $t('email') }}
          </ion-label>
          <ion-input type="email" :value="email" @ionInput="email = $event.target.value" />
        </ion-item>
      </ion-list>
    </ion-content>

    <ion-footer>
      <Error :error="error" />

      <ion-button expand="full" @click="updateInformation">
        {{ $t('updateinformation') }}
      </ion-button>
    </ion-footer>
  </base-modal>
</template>

<script>
    import {UPDATE_FACILITY_INFORMATION} from "../../graphql/mutations";
    import BaseModal from './BaseModal.vue';

    export default {
      components: {
        BaseModal,
      },
      data() {
        return {
          email: null,
          website: null,
          phone: null,
          error: null,
        }
      },
      created() {
        this.email = this.$parent.facility.email;
        this.website = this.$parent.facility.website;
        this.phone = this.$parent.facility.phone;
      },
      methods: {
        close() {
          this.$ionic.modalController.dismiss();
        },
        async updateInformation() {
          try {
            const response = await this.$apollo.mutate({
                mutation: UPDATE_FACILITY_INFORMATION,
                variables: {
                  id: this.$parent.facility.id,
                  data: {
                    website: this.website,
                    email: this.email,
                    phone: this.phone
                  }
                },
              });

              this.$parent.$emit('updateFacilityInformation', response.data.updateFacilityInformation)

              this.close();
          } catch(error) {
            // eslint-disable-next-line no-console
            console.error(error)
            this.error = error;
          }
        }
      }
    }
</script>
