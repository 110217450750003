<template>
  <base-modal :title="$t('searchuser')" @close="close">
    <template v-slot:header>
      <ion-toolbar>
        <ion-searchbar :value="search" @ionInput="search = $event.target.value" />
      </ion-toolbar>
    </template>
    <ion-content class="ion-padding">
      <ion-list>
        <ion-item v-for="member in searchMembers" :key="member.id" @click="addMember(member)">
          <ion-label color="white">
            <p>{{ member.first_name }} {{ member.last_name }}</p>
            <p>{{ member.email }}</p>
          </ion-label>
        </ion-item>
      </ion-list>
    </ion-content>
  </base-modal>
</template>

<script>
import { SEARCH_MEMBERS } from '@/graphql/queries';
import BaseModal from "@/components/modals/BaseModal";

  export default {
    components: {BaseModal},
    props: {
      facility_id: {}
    },
    data() {
       return {
        search: '',
      }
    },
    apollo: {
      searchMembers: {
        query: SEARCH_MEMBERS,
        fetchPolicy: 'network-only',
        debounce: 1000,
        variables() {
          return {
            facility_id: this.facility_id,
            search: this.search
          }
        }
      }
    },
    methods: {
      close() {
        this.$ionic.modalController.dismiss();
      },
      addMember(member) {
        this.$parent.member = member;
        this.close();
      }
    }
  }
</script>
