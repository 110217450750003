<template>
  <ion-item>
    <ion-thumbnail slot="start" :style="thumbStyle">
      <img :src="`${$apiStorageUrl}/${ad.image_url}`">
    </ion-thumbnail>
    <ion-label text-wrap>
      <ion-text color="moto">
        <h2>{{ ad.title }}</h2>
      </ion-text>

      <ion-text color="white">
        <h3>
          {{ $t('impressions') }}: {{ ad.impressions_count }}
        </h3>
      </ion-text>
      <ion-text color="white">
        <h3>
          {{ $t('clicks') }}: {{ ad.clicks_count }}
        </h3>
      </ion-text>
    </ion-label>
  </ion-item>
</template>

<style scoped>
  ion-thumbnail {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  ion-thumbnail > img {
    height: auto;
  }
</style>
<script>

  export default {
    props: {
      ad: {
        required: true,
        type: Object,
      },
    },
    computed: {
      thumbStyle() {
        return {
          backgroundColor: this.ad.color,
        }
      }
    },
    methods: {}
  }
</script>

