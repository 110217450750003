<template>
  <base-modal :title="$t('choosemembership')" @close="$ionic.modalController.dismiss()">
    <ion-content>
      <ion-list>
        <ion-radio-group>
          <ion-list-header>
            {{ $t('memberships') }}
          </ion-list-header>

          <ion-item v-for="public_subscription in $parent.facility.public_subscriptions" :key="public_subscription.id" @click="selectedMembership = public_subscription.id">
            <div>
              <ion-label>{{ public_subscription.name }}</ion-label>
              <ion-badge color="moto">{{ public_subscription.price | stripeAmount }} / {{ $t('peryear') }}</ion-badge>
            </div>
            <ion-radio slot="end" />
          </ion-item>
        </ion-radio-group>
      </ion-list>
    </ion-content>

    <ion-footer>
      <ion-button :disabled="!selectedMembership" @click="buySubscription" expand="full" color="moto">{{ $t('buymembership') }}</ion-button>
    </ion-footer>
  </base-modal>
</template>

<script>
import { BUY_SUBSCRIPTION } from '../../graphql/mutations'
import BaseModal from './BaseModal.vue'
import { STRIPE_API_KEY } from "@/configs";
export default {
  components: {
    BaseModal,
  },
  data() {
    return {
      selectedMembership: null,
    }
  },
  methods: {
    async buySubscription() {
      const { data: { buySubscription: sessionId }} = await this.$apollo.mutate({
        mutation: BUY_SUBSCRIPTION,
        variables: {
          subscription_id: this.selectedMembership,
        }
      });

      const stripe = window.Stripe(STRIPE_API_KEY);

       await stripe.redirectToCheckout({
                  // Make the id field from the Checkout Session creation API response
                  // available to this file, so you can provide it as parameter here
                  // instead of the {{CHECKOUT_SESSION_ID}} placeholder.
          sessionId,
        })
    }
  }
}
</script>