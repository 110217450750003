<template>
  <ion-list>
    <ion-list-header>
      <ion-label>{{ $t('popoveractions') }}</ion-label>
    </ion-list-header>

    <ion-item v-if="exportTransfersEnabled" @click="exportFacilityTransfers" :disabled="loading">
      <ion-icon slot="start" name="logo-euro" color="white" />
      <ion-label text-wrap>
        {{ $t('exporttransfersaction') }}
      </ion-label>
    </ion-item>

    <ion-item button v-if="exportMembershipsEnabled" @click="exportFacilityMemberships" :disabled="loading">
      <ion-icon slot="start" name="person" color="white" />
      <ion-label text-wrap>
        {{ $t('exportmembershipsaction') }}
      </ion-label>
    </ion-item>
  </ion-list>
</template>

<script>
  import { EXPORT_FACILITY_TRANSFERS, EXPORT_FACILITY_MEMBERS } from '@/graphql/mutations';
  import { FEATURE_FLAG_MAPPING } from "@/configs";
  import { OPTIMIZELY_IS_READY, CURRENT_USER_GETTER } from "@/store/store-getters";

  export default {
    props: ['facilityId'],
    data() {
      return {
        loading: false,
      }
    },
    computed: {
      exportTransfersEnabled() {
        return this.$store.getters[OPTIMIZELY_IS_READY] && this.$optimizely.isFeatureEnabled(FEATURE_FLAG_MAPPING.CAN_SEE_TRANSFERS, this.$store.getters[CURRENT_USER_GETTER].id, {
          facility_id: this.facilityId
        });
      },
      exportMembershipsEnabled() {
        return this.$store.getters[OPTIMIZELY_IS_READY] && this.$optimizely.isFeatureEnabled(FEATURE_FLAG_MAPPING.MANUAL_MEMBERSHIPS, this.$store.getters[CURRENT_USER_GETTER].id, {
          facility_id: this.facilityId
        });
      },

    },
    methods: {
      async exportFacilityTransfers() {
        this.loading = true;
        try {
          const response = await this.$apollo.mutate({
            mutation: EXPORT_FACILITY_TRANSFERS,
            variables: {
              facility_id: this.facilityId
            }
          })

          this.$parent.$emit('exportReady', response.data.exportFacilityTransfers);
        } catch (err) {
          this.showError(err);
        } finally {
          this.loading = false;
        }
      },
      async exportFacilityMemberships() {
        this.loading = true;
        try {
          const response = await this.$apollo.mutate({
            mutation: EXPORT_FACILITY_MEMBERS,
            variables: {
              facility_id: this.facilityId
            }
          })

          this.$parent.$emit('exportReady', response.data.exportFacilityMembers);
        } catch (err) {
          await this.showError(err);
        } finally {
          this.loading = false;
        }
      },
      async showError(err) {
        const toast = await this.$ionic.toastController.create({
          color: 'danger',
          duration: 3000,
          message: err.message.replace('GraphQL error:', '').trim()
        });

        await toast.present();
        // eslint-disable-next-line no-console
        console.error(err);
      }
    }
  }
</script>
