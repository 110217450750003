<template>
  <ion-list lines="full">
    <ion-radio-group @ionChange="changePaymentMethod($event.target.value)">
      <ion-list-header>
        <ion-label>{{ $t('paymentmethod') }}</ion-label>
      </ion-list-header>

      <ion-item>
        <ion-thumbnail slot="start">
          <img src="/icons/payment-ideal.png">
        </ion-thumbnail>
        <ion-label>{{ $t('paymentmethodideal') }}</ion-label>
        <ion-radio value="ideal" />
      </ion-item>

      <ion-item>
        <ion-thumbnail slot="start">
          <img src="/icons/payment-card.png">
        </ion-thumbnail>
        <ion-label>{{ $t('paymentmethodcard') }}</ion-label>
        <ion-radio value="card" />
      </ion-item>

      <ion-item>
        <ion-thumbnail slot="start">
          <img src="/icons/payment-bancontact.png">
        </ion-thumbnail>
        <ion-label>{{ $t('paymentmethodbancontact') }}</ion-label>
        <ion-radio value="bancontact" />
      </ion-item>
    </ion-radio-group>
  </ion-list>
</template>

<script>
  export default {
    props: {
      value: {
        default: null,
        type: String,
      },
    },
    methods: {
      changePaymentMethod(paymentMethod) {
        this.$emit('change', paymentMethod);
      },
      itemColor(paymentMethod) {
        return (paymentMethod === this.value) ? 'medium' : '';
      }
    }
  }
</script>

<style scoped>
  ion-thumbnail {
    width: 35px;
    height: 35px;
  }
</style>
